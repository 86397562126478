import Vuex from 'vuex'
import Vue from 'vue'
import createMutationsSharer from 'vuex-shared-mutations'
import SimpleModuleMaker from './simple-module-maker'
import createCustomPropertiesModule from './modules/custom-properties'
import createCrmStagesModule from './modules/crm-stages'
import createSavedFiltersModule from './modules/saved-filters'
import createTagsModule from './modules/tags'

Vue.use(Vuex)

export default function (w) {
  const plugins = []

  try {
    const sharer = createMutationsSharer({
      predicate: (mutation, state) => {
        const notSharedMutations = [
          'Video/loading',
          'Video/loaded'
        ]

        return notSharedMutations.indexOf(mutation.type) === -1
      }
    })

    plugins.push(sharer)
  } catch (e) {

  }

  return new Vuex.Store({
    plugins,
    modules: {
      CrmDealPipelines: SimpleModuleMaker('api.crm-deal-pipeline.index'),
      CustomProperties: createCustomPropertiesModule(w.maps),
      CrmStages: createCrmStagesModule(w.maps),
      EmailSignature: SimpleModuleMaker('api.email-signature.show'),
      SavedFilters: createSavedFiltersModule(w.maps),
      Tags: createTagsModule(w.maps),
      TeamMembers: SimpleModuleMaker('api.team.member.index', { no_pagination: '1' }),
      TeamGroups: SimpleModuleMaker('api.team.group.index'),
      TeamRoles: SimpleModuleMaker('api.team.role.index'),
      YoutubeCredentials: SimpleModuleMaker('api.youtube_credential.index')
    }
  })
}
