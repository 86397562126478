import Vue from 'vue'
import { ReadableStream } from 'web-streams-polyfill/ponyfill'
import $ from 'jquery'
import Store from 'store'
import VueLazyload from 'vue-lazyload'
import vueRouter from './router'
import createStore from './vuex'

window.constants = require('./generated/constants')
window.maps = require('./generated/maps')

window.Bus = new Vue()
window.$ = window.jQuery = $
require('bootstrap')
require('slick-carousel')
require('./configuration')

if (!window.ReadableStream) {
  window.ReadableStream = ReadableStream
}

require('./components/bootstrap')
Vue.use(VueLazyload)
if ($('#spark-app').length > 0) {
  window.app = new Vue({
    el: '#spark-app',
    router: vueRouter,
    store: createStore(window),
    data () {
      return {
        user: Spark.state.user,
        currentTeam: Spark.state.currentTeam
      }
    }
  })
}

$(document).ready(() => {
  const $body = $('body')
  $('#kt_header_mobile_topbar_toggle').click(() => {
    $body.toggleClass('topbar-mobile-on')
  })
  $('#kt_aside_mobile_toggle').click(() => {
    $('#kt_aside').toggleClass('aside-on')
    $body.toggleClass('aside-on')
  })
  $('.aside-overlay, .aside a').on('click', () => {
    $('#kt_aside').removeClass('aside-on')
    $body.removeClass('aside-on')
  })

  // Aside toggle
  let insideTm
  let outsideTm

  function getMatchedGroupRoute (route) {
    if (route.matched.length > 0) {
      const groupRoutes = route.matched.filter(route => typeof route.meta.group !== 'undefined')

      if (groupRoutes.length > 0) {
        return groupRoutes[0]
      }
    }

    return null
  }

  $('#kt_aside_toggle').click(() => {
    $body.toggleClass('aside-minimize')
    if (vueRouter.currentRoute) {
      const groupRoute = getMatchedGroupRoute(vueRouter.currentRoute)

      if (groupRoute !== null) {
        const routeGroup = groupRoute.meta.group
        Store.set(`aside-minimized:${routeGroup}`, $body.hasClass('aside-minimize') ? '1' : '0')
      }
    }
  })
  $('#kt_aside_menu').on('mouseenter', e => {
    e.preventDefault()
    if (outsideTm) {
      clearTimeout(outsideTm)
      outsideTm = null
    }

    if (insideTm) {
      clearTimeout(insideTm)
      insideTm = null
    }

    insideTm = setTimeout(() => {
      if ($body.hasClass('aside-minimize')) {
        $body.addClass('aside-minimize-hover')
      }
    }, 50)
  })
  $('#kt_aside').on('mouseleave', e => {
    e.preventDefault()

    if (insideTm) {
      clearTimeout(insideTm)
      insideTm = null
    }

    if (outsideTm) {
      clearTimeout(outsideTm)
      outsideTm = null
    }

    outsideTm = setTimeout(() => {
      if ($body.hasClass('aside-minimize-hover')) {
        $body.removeClass('aside-minimize-hover')
      }
    }, 100)
  })
  vueRouter.afterEach((to, from) => {
    const groupRoute = getMatchedGroupRoute(to)

    if (groupRoute !== null) {
      const routeGroup = groupRoute.meta.group
      const minimized = Store.get(`aside-minimized:${routeGroup}`)

      if (minimized === '1' || (typeof minimized === 'undefined' && groupRoute.meta['aside-minimized'] === true)) {
        $body.addClass('aside-minimize')
      } else {
        $body.removeClass('aside-minimize')
      }
    } else {
      $body.removeClass('aside-minimize')
    }
  })
  // end Aside toggle

  // Quick panels
  $body.on('click', '.offcanvas-overlay, .kt_quick_panel_close', () => {
    $('#quick-panel .offcanvas-on').each((index, el) => {
      const id = $(el).attr('id')

      if (id) {
        window.Bus.$emit('hide-quick-panel', '#' + id)
      }
    })
    $('.offcanvas-overlay').addClass('d-none')
  })

  $body.on('click', '.dropdown-menu.hold-on-click', function (e) {
    e.stopPropagation()
  })
  window.Bus.$on('show-quick-panel', selector => {
    $body.find(selector).addClass('offcanvas-on')
    $('.offcanvas-overlay').removeClass('d-none')
  })
  window.Bus.$on('hide-quick-panel', selector => {
    $body.find(selector).removeClass('offcanvas-on')
    $('.offcanvas-overlay').addClass('d-none')
  })
})
