const constants = require('../generated/constants')

// Do not set route name to standalone routes
export default [
  {
    path: '/subdomain',
    component: () => import('../modules/standalones/subdomain'),
    meta: {
      title: 'Create Team'
    }
  },
  {
    path: '/verify-phone',
    component: () => import('../modules/standalones/verify-phone'),
    meta: {
      title: 'Verify your Identity'
    }
  },
  {
    path: '/invite-team-members',
    component: () => import('../modules/standalones/invite-team-members'),
    meta: {
      title: 'Invite others to your team'
    }
  },
  {
    path: '/redeem-partner-code',
    component: () => import('../modules/standalones/redeem-partner-code')
  },
  {
    path: '/tools/convertor',
    component: () => import('../modules/standalones/tools/convertor'),
    meta: {
      title: 'Video Converter'
    }
  },
  {
    path: '/messaging',
    name: 'standalones.messaging',
    component: () => import('../modules/standalones/messaging'),
    meta: {
      title: 'Messaging',
      rolePermission: constants.App_Models_TeamRole.PERMISSION_MESSAGING
    },
    children: [
      {
        path: 'online',
        name: 'standalones.messaging.online',
        component: () => import('../modules/standalones/messaging/online'),
        meta: {
          messagingTab: 'online'
        }
      },
      {
        path: 'sms',
        name: 'standalones.messaging.sms',
        component: () => import('../modules/standalones/messaging/sms'),
        meta: {
          messagingTab: 'sms'
        }
      }
    ]
  },
  {
    path: '/campaign/:slug/info',
    component: () => import('../modules/dashboard-v5/campaigns/info.vue'),
    meta: {
      title: 'Campaign Details'
    }
  },
  {
    path: '/v/:slug/edit',
    components: {
      default: () => import('../components/video/edit'),
      aside: () => import('../modules/dashboard-v5/sub-views/aside')
    },
    meta: {
      group: 'video-edit',
      'aside-minimized': true
    }
  },
  {
    path: '/avatar-video/:hash_id',
    name: 'standalones.avatar-video.show',
    component: () => import('../modules/standalones/avatar-video/avatar-video.vue'),
    meta: {
      title: 'Avatar Video'
    }
  }
]
