import axios from 'axios'

export default function (maps) {
  return {
    namespaced: true,
    state: () => {
      const state = {}

      Object.keys(maps.App_Models_CrmStage.typesMap).forEach(type => {
        state[type] = {
          loaded: false,
          loading: false,
          data: []
        }
      })

      return state
    },
    mutations: {
      loading (state, { type }) {
        state[type] = {
          loaded: false,
          loading: true,
          data: []
        }
      },
      loaded (state, { type, data }) {
        state[type] = {
          loaded: true,
          loading: false,
          data
        }
      }
    },
    actions: {
      loadData ({ commit, state }, { type, force = false }) {
        if ((force || !state[type].loaded) && !state[type].loading) {
          commit('loading', { type })
          axios.get(Spark.router('api.crm_stage.index', { type }))
            .then(resp => {
              commit('loaded', { type, data: resp.data })
            })
        }
      }
    }
  }
}
