import Vue from 'vue'
import $ from 'jquery'
import Router from 'vue-router'
import dashboardV5 from './dashboard-v5'
import settingsV5 from './settings-v5'
import kiosk from './kiosk'
import standalones from './standalones'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkExactActiveClass: 'exact-active',
  linkActiveClass: 'active',
  routes: [...settingsV5, ...dashboardV5, ...kiosk, ...standalones],
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve, reject) => {
        // without setTimeout, it will not scroll to the hash
        // the component is not yet rendered when this is called, delay 100ms
        setTimeout(() => {
          resolve({
            selector: to.hash,
            behavior: 'smooth'
          })
        }, 100)
      })
    }

    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.length > 0 && typeof to.meta.rolePermission !== 'undefined' && !Spark.roleHasPermission(to.meta.rolePermission)) {
    if (to.meta.alertNoPermission) {
      window.SwalTimer.fire({
        icon: 'error',
        title: 'No permission, please contact your admin.'
      })
      next(false)
    } else {
      location.href = Spark.router('web.pages.no-role-permission')
    }
  } else {
    next()
  }
})

const APP_NAME = process.env.MIX_APP_NAME

router.afterEach((to, from) => {
  // Re-title only works for matched routes
  if (to.matched.length > 0) {
    Vue.nextTick(() => {
      if (to.meta.title) {
        document.title = to.meta.title + ' - ' + APP_NAME
      } else {
        document.title = APP_NAME
      }

      if (to.meta.bodyClass) {
        $('body').addClass(to.meta.bodyClass)
      }
    })
  }

  if (from && from.meta && from.meta.bodyClass) {
    $('body').removeClass(from.meta.bodyClass)
  }
})

export default router
