const constants = require('../generated/constants')

export default [{
  path: '/settings',
  meta: {
    group: 'settings'
  },
  components: {
    default: () => import('../modules/settings-v5/empty'),
    aside: () => import('../modules/settings-v5/sub-views/aside')
  },
  children: [
    {
      path: 'security',
      name: 'settings.security',
      component: () => import('../modules/settings-v5/security'),
      meta: {
        title: 'Security'
      }
    },
    {
      path: 'profile',
      name: 'settings.profile',
      component: () => import('../modules/settings-v5/profile'),
      meta: {
        title: 'Profile'
      }
    },
    {
      path: 'social',
      name: 'settings.social',
      component: () => import('../modules/settings-v5/social'),
      meta: {
        title: 'Social'
      }
    },
    {
      path: 'notifications',
      name: 'settings.notifications',
      component: () => import('../modules/settings-v5/notification'),
      meta: {
        title: 'Notifications'
      }
    },
    {
      path: 'customization',
      name: 'settings.customization',
      component: () => import('../modules/settings-v5/customization'),
      meta: {
        title: 'Customization'
      }
    },
    {
      path: 'teams',
      name: 'settings.teams',
      component: () => import('../modules/settings-v5/team/profile'),
      meta: {
        title: 'My Team'
      }
    },
    {
      path: 'announcements',
      name: 'settings.teams.announcements',
      component: () => import('../modules/settings-v5/team/announcements'),
      meta: {
        title: 'Team Announcements',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_ANNOUNCEMENT
      }
    },
    {
      path: 'videos/tags',
      name: 'settings.videos.tags',
      component: () => import('../modules/settings-v5/team/video-tags'),
      meta: {
        title: 'Video Tags'
      }
    },
    {
      path: 'contacts/tags',
      name: 'settings.contacts.tags',
      component: () => import('../modules/settings-v5/team/contact-tags'),
      meta: {
        title: 'Contact Tags'
      }
    },
    {
      path: 'scripts/tags',
      name: 'settings.scripts.tags',
      component: () => import('../modules/settings-v5/team/script-tags'),
      meta: {
        title: 'Script Tags'
      }
    },
    {
      path: 'video-presets',
      name: 'settings.video-presets.index',
      redirect: { name: 'dashboard.video-presets.index' }
    },
    {
      path: 'configuration',
      name: 'settings.configuration',
      component: () => import('../modules/settings-v5/team/configuration'),
      meta: {
        title: 'Team Configuration'
      }
    },
    {
      path: 'configuration-sets',
      name: 'settings.configuration_sets.index',
      component: () => import('../modules/settings-v5/team/configuration-sets/index'),
      meta: {
        title: 'Configuration Sets',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_TEAM_CONFIGURATION_SET
      }
    },
    {
      path: 'configuration-sets/create',
      name: 'settings.configuration_sets.create',
      component: () => import('../modules/settings-v5/team/configuration-sets/form'),
      meta: {
        title: 'Create New Configuration Set',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_TEAM_CONFIGURATION_SET
      }
    },
    {
      path: 'configuration-sets/:id',
      name: 'settings.configuration_sets.edit',
      component: () => import('../modules/settings-v5/team/configuration-sets/form'),
      meta: {
        title: 'Edit Configuration Set',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_TEAM_CONFIGURATION_SET
      }
    },
    {
      path: 'lifecycle-stages',
      name: 'settings.contact_stages',
      component: () => import('../modules/settings-v5/team/contact-stages'),
      meta: {
        title: 'Lifecycle Stages'
      }
    },
    {
      path: 'deal-pipelines',
      name: 'settings.crm_deal_pipelines.index',
      component: () => import('../modules/settings-v5/team/deal-pipelines'),
      meta: {
        title: 'Deal Pipelines'
      }
    },
    {
      path: 'deal-pipelines/:id',
      name: 'settings.crm_deal_pipelines.stages',
      component: () => import('../modules/settings-v5/team/deal-pipeline-stages.vue'),
      meta: {
        title: 'Deal Pipeline Stages',
        bodyClass: 'subheader-enabled subheader-fixed'
      }
    },
    {
      path: 'properties',
      name: 'settings.crm_custom_properties',
      component: () => import('../modules/settings-v5/team/custom-properties/custom-properties'),
      meta: {
        title: 'Custom Properties'
      }
    },
    {
      path: 'saved-filters',
      name: 'settings.saved_filters',
      component: () => import('../modules/settings-v5/team/saved-filters'),
      meta: {
        title: 'Saved Filters'
      }
    },
    {
      path: 'broadcast-settings',
      name: 'settings.broadcast-settings',
      component: () => import('../modules/settings-v5/team/broadcast-settings/list'),
      meta: {
        title: 'Broadcast Settings'
      }
    },
    {
      path: 'upgrade/contacts',
      name: 'settings.upgrade.contacts',
      component: () => import('../modules/settings-v5/team/upgrade/contacts'),
      meta: {
        title: 'Upgrade Contact Limits',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_TEAM_BILLING
      }
    },
    {
      path: 'upgrade/storage',
      name: 'settings.upgrade.storage',
      component: () => import('../modules/settings-v5/team/upgrade/storage'),
      meta: {
        title: 'Upgrade Storage Limits',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_TEAM_BILLING
      }
    },
    {
      path: 'upgrade/transcribe',
      name: 'settings.upgrade.transcribe',
      component: () => import('../modules/settings-v5/team/upgrade/transcribe'),
      meta: {
        title: 'Transcription Plans',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_TEAM_BILLING
      }
    },
    {
      path: 'upgrade/automation',
      name: 'settings.upgrade.automation',
      component: () => import('../modules/settings-v5/team/upgrade/automation'),
      meta: {
        title: 'Automation Addon',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_TEAM_BILLING
      }
    },
    {
      path: 'upgrade/custom-domain',
      name: 'settings.upgrade.custom-domain',
      component: () => import('../modules/settings-v5/team/upgrade/custom-domain'),
      meta: {
        title: 'Custom Domain Name Addon',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_TEAM_BILLING
      }
    },
    {
      path: 'video-templates',
      name: 'settings.video-templates.index',
      component: () => import('../modules/settings-v5/team/video-templates/index'),
      meta: {
        title: 'Video Templates',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_VIDEO_TEMPLATE
      }
    },
    {
      path: 'payment-method',
      name: 'settings.payment-method',
      component: () => import('../modules/settings-v5/team/payment-method'),
      meta: {
        title: 'Payment Method',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_TEAM_BILLING
      }
    },
    {
      path: 'youtube',
      name: 'settings.youtube-credentials',
      component: () => import('../modules/settings-v5/team/youtube-credentials'),
      meta: {
        title: 'Youtube Accounts'
      }
    },
    {
      path: 'invoices',
      name: 'settings.invoices',
      component: () => import('../modules/settings-v5/team/invoices'),
      meta: {
        rolePermission: constants.App_Models_TeamRole.PERMISSION_TEAM_BILLING,
        meta: {
          title: 'Invoices'
        }
      }
    },
    {
      path: 'integrations',
      name: 'settings.integrations',
      component: () => import('../modules/settings-v5/integrations'),
      meta: {
        title: 'Marketplace',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_INTEGRATION
      }
    },
    {
      path: 'api-tokens',
      name: 'settings.api-tokens',
      component: () => import('../modules/settings-v5/api-tokens'),
      meta: {
        title: 'API Tokens'
      }
    }
  ]
}]
