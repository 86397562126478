export default [{
  path: '/spark/kiosk',
  components: {
    default: () => import('../modules/kiosk/empty'),
    aside: () => import('../modules/kiosk/sub-views/aside')
  },
  children: [
    {
      path: 'activity-report',
      name: 'kiosk.activity-report',
      component: () => import('../modules/kiosk/activity-report')
    }, {
      path: 'metrics',
      name: 'kiosk.metrics',
      component: () => import('../modules/kiosk/metrics')
    }, {
      path: 'kiosk-users',
      name: 'kiosk.kiosk-users',
      component: () => import('../modules/kiosk/kiosk-users')
    }, {
      path: 'users',
      name: 'kiosk.users',
      component: () => import('../modules/kiosk/users/index')
    }, {
      path: 'subscribers',
      name: 'kiosk.subscribers',
      component: () => import('../modules/kiosk/subscribers')
    }, {
      path: 'cancellations',
      name: 'kiosk.cancellations',
      component: () => import('../modules/kiosk/cancellations')
    }, {
      path: 'users/:id',
      name: 'kiosk.users.show',
      component: () => import('../modules/kiosk/users/show')
    }, {
      path: 'leads',
      name: 'kiosk.leads',
      component: () => import('../modules/kiosk/leads')
    }, {
      path: 'redirections',
      name: 'kiosk.redirections',
      component: () => import('../modules/kiosk/redirections')
    }, {
      path: 'teams',
      name: 'kiosk.teams',
      component: () => import('../modules/kiosk/teams/index')
    }, {
      path: 'teams/:id',
      name: 'kiosk.teams.show',
      component: () => import('../modules/kiosk/teams/show')
    }, {
      path: 'team_sso_configurations',
      name: 'kiosk.team_sso_configurations',
      component: () => import('../modules/kiosk/team-sso-configurations/list')
    }, {
      path: 'announcements',
      name: 'kiosk.announcements',
      component: () => import('../modules/kiosk/announcements/index')
    }, {
      path: 'announcements/create',
      name: 'kiosk.announcements.create',
      component: () => import('../modules/kiosk/announcements/edit')
    }, {
      path: 'announcements/:id',
      name: 'kiosk.announcements.show',
      component: () => import('../modules/kiosk/announcements/edit')
    }, {
      path: 'settings',
      name: 'kiosk.settings',
      component: () => import('../modules/kiosk/settings')
    }, {
      path: 'markdowns',
      name: 'kiosk.markdowns',
      component: () => import('../modules/kiosk/markdowns')
    }, {
      path: 'video-analysis-settings',
      name: 'kiosk.video-analysis-settings',
      component: () => import('../modules/kiosk/video-analysis-settings')
    }, {
      path: 'partners',
      name: 'kiosk.partners',
      component: () => import('../modules/kiosk/dubb-objects/partners')
    }, {
      path: 'coaches',
      name: 'kiosk.coaches',
      component: () => import('../modules/kiosk/dubb-objects/coaches')
    }, {
      path: 'integrations',
      name: 'kiosk.integrations',
      component: () => import('../modules/kiosk/dubb-objects/integrations')
    }, {
      path: 'optimize-for',
      name: 'kiosk.optimize-for',
      component: () => import('../modules/kiosk/dubb-objects/optimize-for')
    }, {
      path: 'dashboard-messages',
      name: 'kiosk.dashboard-messages',
      component: () => import('../modules/kiosk/dubb-objects/dashboard-messages')
    }, {
      path: 'crm-options',
      name: 'kiosk.crm-options',
      component: () => import('../modules/kiosk/dubb-objects/crm-options')
    }, {
      path: 'partner-promo-code',
      name: 'kiosk.partner-promo-code',
      component: () => import('../modules/kiosk/partner-promo-code')
    }, {
      path: 'email-templates',
      name: 'kiosk.email-templates.index',
      component: () => import('../modules/kiosk/templates/email-templates/index')
    }, {
      path: 'email-templates/create',
      name: 'kiosk.email-templates.create',
      component: () => import('../modules/kiosk/templates/email-templates/create_or_update')
    }, {
      path: 'email-templates/edit/:hash_id',
      name: 'kiosk.email-templates.edit',
      component: () => import('../modules/kiosk/templates/email-templates/create_or_update')
    }, {
      path: 'video-templates',
      name: 'kiosk.video-templates.index',
      component: () => import('../modules/kiosk/templates/video-templates/index')
    }, {
      path: 'video-templates/create',
      name: 'kiosk.video-templates.create',
      component: () => import('../modules/kiosk/templates/video-templates/form')
    }, {
      path: 'video-templates/edit/:hash_id',
      name: 'kiosk.video-templates.edit',
      component: () => import('../modules/kiosk/templates/video-templates/form')
    }, {
      path: 'landing-page-templates',
      name: 'kiosk.landing-page-templates.index',
      component: () => import('../modules/kiosk/templates/landing-page-templates/index')
    }, {
      path: 'pages',
      name: 'kiosk.pages.index',
      component: () => import('../modules/kiosk/pages/index')
    }, {
      path: 'pages/settings',
      name: 'kiosk.pages.settings',
      component: () => import('../modules/kiosk/pages/settings')
    }, {
      path: 'pages/create',
      name: 'kiosk.pages.create',
      component: () => import('../modules/kiosk/pages/edit')
    }, {
      path: 'pages/edit/:id',
      name: 'kiosk.pages.edit',
      component: () => import('../modules/kiosk/pages/edit')
    }, {
      path: 'pages/clone/:id',
      name: 'kiosk.pages.clone',
      component: () => import('../modules/kiosk/pages/edit')
    }, {
      path: 'pages/elements',
      name: 'kiosk.pages.elements',
      component: () => import('../modules/kiosk/pages/elements/index')
    }, {
      path: 'pages/elements/create',
      name: 'kiosk.pages.elements.create',
      component: () => import('../modules/kiosk/pages/elements/edit')
    }, {
      path: 'pages/elements/edit/:id',
      name: 'kiosk.pages.elements.edit',
      component: () => import('../modules/kiosk/pages/elements/edit')
    }, {
      path: 'pages/elements/clone/:id',
      name: 'kiosk.pages.elements.clone',
      component: () => import('../modules/kiosk/pages/elements/edit')
    }, {
      path: 'mrss',
      name: 'kiosk.mrss',
      component: () => import('../modules/kiosk/dubb-objects/mrss')
    }, {
      path: 'faqs',
      name: 'kiosk.faqs',
      component: () => import('../modules/kiosk/dubb-objects/faqs')
    }, {
      path: 'lead-emails',
      name: 'kiosk.lead-emails.index',
      component: () => import('../modules/kiosk/dubb-objects/lead-emails/index.vue')
    }, {
      path: 'lead-emails/:type/create',
      name: 'kiosk.lead-emails.create',
      component: () => import('../modules/kiosk/dubb-objects/lead-emails/edit.vue')
    }, {
      path: 'lead-emails/:type/edit/:id',
      name: 'kiosk.lead-emails.edit',
      component: () => import('../modules/kiosk/dubb-objects/lead-emails/edit.vue')
    }
  ]
}]
