import axios from 'axios'

export default function (routeName, extraRouteParams = {}) {
  return {
    namespaced: true,
    state: () => ({
      loaded: false,
      loading: false,
      data: []
    }),
    mutations: {
      loading (state) {
        state.loaded = false
        state.loading = true
      },
      loaded (state, { data }) {
        state.loaded = true
        state.loading = false
        state.data = data
      }
    },
    actions: {
      loadData ({ commit, state }, { force = false }) {
        if ((force || !state.loaded) && !state.loading) {
          commit('loading')
          axios.get(Spark.router(routeName, extraRouteParams))
            .then(resp => {
              commit('loaded', { data: resp.data })
            })
        }
      }
    }
  }
}
